import React from 'react';
import { graphql } from 'gatsby';
import { MaxWidth } from '@allthingswww/client-act-shared';
import styled from 'styled-components';
import ContentMdx from '../components/ContentMdxs';
import Layout from '../components/Layout';

const NewMaxWidth = styled(MaxWidth)`
  @media (max-width: 1060px) {
    margin: auto;
  }
`;

export const HomePageTemplate = ({ data, location }) => {
  const { frontmatter, body } = data.page;
  const { title, subtitle } = frontmatter;

  return (
    <Layout
      headerBackGroundImage="HeroImage4k.png"
      title={title}
      subtitle={subtitle}
      button="Membership Benefits"
      buttonTarget="/purchase-membership"
      meta={{ data, location }}
    >
      <NewMaxWidth>
        <ContentMdx source={body} />
      </NewMaxWidth>
    </Layout>
  );
};

export default HomePageTemplate;

export const pageQuery = graphql`
  query HomePageMDX($id: String!) {
    page: mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
        subtitle
        featuredImage
      }
    }
  }
`;
